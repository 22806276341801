import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Stack
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { MediaImage } from "../../common/Media";
import { SectionContent } from "../../common/Section";


interface Props {
  // Global Props
  direction: "row" | "row-reverse"

  // Heading props
  heading?: string

  // Description props
  description?: string[]

  // Image props
  image: IGatsbyImageData
  imageBackground?: string
  imageMaxW?: object | string
  imageMaxH?: object | string
};


export const TermitesFlexbox: React.FC<Props> = ( props ) => {
  return (
    <Stack
      direction={{
        base: 'column',
        lg: props.direction
      }}
      spacing={{
        base: 16,
        lg: '117px'
      }}
      alignItems="center"
      w="100%"
    >
      <SectionContent
        justifyContent="flex-start"
        alignItems="left"
        componentWidth={{
          lg: '432px',
          xl: '432px',
          '2xl': '550px'
        }}
        textAlign={{
          base: 'center',
          lg: 'left'
        }}
        heading={ props.heading }
        headingWidth={{
          base: '100%'
        }}
        description={ props.description }
        Button={
          <Box>
            <Button
              as={ GatsbyLink }
              to="/contact"
              variant="solid"
              size="lg"
              colorScheme="primary"
              border="1px"
              borderColor="primary.900"
              borderRadius="24px"
              _hover={{
                background: 'transparent',
                color: 'primary.900'
              }}
            >
              Contact Us
            </Button>
          </Box>
        }
      />
      <MediaImage
        hasFrame
        containerWidth="100%"
        containerHeight={{
          base: '300px',
          sm: '570px',
          md: '620px',
          lg: '412px',
          xl: '651px',
          '2xl': '806px'
        }}
        borderRadius="0px 32px"
        background={ props.imageBackground ? props.imageBackground : 'background.cloudburst' }
        image={ props.image }
        imageMaxW={ props.imageMaxW }
        imageMaxH={ props.imageMaxH }
        alt="temp alt"
        objectFit="none"
      />
    </Stack>
  )
}
