import * as React from "react";
import {
  Divider,
  VStack
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
  align: "flex-start" | "center"
  spacing?: object | number
  dividerVisible?: boolean
};


export const Stack: React.FC<Props> = ( props ) => {
  return (
    <VStack
      align={ props.align }
      spacing={ props.spacing }
    >
      <Divider
        display={ props.dividerVisible ? 'block' : 'none' }
      />
      { props.children }
    </VStack>
  )
}
