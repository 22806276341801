import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";


interface Props {
  heading: string
  description: string
  amount: string
};


export const PromotionsItem: React.FC<Props> = ( props ) => {
  return (
    <>
      <Flex
        flexDirection={{
          base: 'column',
          md: 'row'
        }}
        justifyContent={{
          md: 'space-between'
        }}
        alignItems={{
          md: 'center'
        }}
        w="100%"
        padding="32px 0"
      >
        <VStack
          align="flex-start"
          spacing={ 6 }
          w={{
            lg: '635px'
          }}
        >
          <Box>
            <Heading
              as="h4"
              color="primary.900"
              fontSize="32px"
              fontWeight={ 400 }
              lineHeight="36px"
            >
              { props.amount } OFF
            </Heading>
            <Heading
              as="h4"
              color="accent.900"
              fontSize="32px"
              fontWeight={ 400 }
              lineHeight="36px"
            >
              { props.heading }
            </Heading>
          </Box>
          <Text
            color="accent.900"
            fontSize="16px"
            lineHeight="21px"
          >
            { props.description }
          </Text>
        </VStack>
        <Box>
          <Button
            variant="outline"
            size="lg"
            color="primary.900"
            borderColor="primary.900"
            borderRadius="24px"
            _hover={{
              background: 'primary.900',
              color: 'white'
            }}
            _focus={{
              boxShadow: 'none !important'
            }}
          >
            Offer Available
          </Button>
        </Box>
      </Flex>
      <Divider />
    </>
  )
}
