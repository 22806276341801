import * as React from "react";
import {
  Grid
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
};


export const PestsGrid: React.FC<Props> = ( props ) => {
  return (
    <Grid
      templateColumns={{
        lg: '1fr 1fr 1fr'
      }}
      gap={{
        base: '32px',
        lg: '50px',
        xl: '150px'
      }}
      w="100%"
    >
      { props.children }
    </Grid>
  )
}
