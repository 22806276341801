import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Divider,
  Heading,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  heading: string
  description: string
  postDate: string
  slug: string
};


export const BlogPostItem: React.FC<Props> = ( props ) => {
  return (
    <>
      <LinkBox
        padding="32px 0"
        transition="all 0.2s ease-in-out"
        _hover={{
          opacity: '0.6'
        }}
      >
        <Stack
          direction={{
            base: 'column',
            md: 'row'
          }}
          justify={{
            md: 'flex-start'
          }}
          align={{
            md: 'flex-start'
          }}
          spacing={{
            lg: '232px',
            xl: '292px',
            '2xl': '352px'
          }}
        >
          <VStack
            align="flex-start"
            spacing={ 2 }
          >
            <Heading
              as="h4"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Article
            </Heading>
            <Text
              color="accent.900"
              fontSize="12px"
              lineHeight="16px"
            >
              { props.postDate }
            </Text>
          </VStack>
          <VStack
            align="flex-start"
            spacing={ 6 }
            w={{
              base: '100%',
              lg: '635px'
            }}
          >
            <Heading
              as="h3"
              color="accent.900"
              fontSize="32px"
              fontWeight={ 400 }
              lineHeight="36px"
            >
              <LinkOverlay
                as={ GatsbyLink }
                to={ props.slug }
              >
                { props.heading }
              </LinkOverlay>
            </Heading>
            <Text
              color="accent.900"
              fontSize="16px"
              lineHeight="21px"
            >
              { props.description }
            </Text>
          </VStack>
        </Stack>
      </LinkBox>
      <Divider />
    </>
  )
}
