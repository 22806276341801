import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { CallToAction } from "../components/CallToAction";
import { BenefitsCarousel, TestimonialsCarousel } from "../components/Carousel";
import { AboutFlexbox, ServiceAreasFlexbox } from "../components/Flexbox";
import { CareerBenefitsGrid, CareerBenefitItem } from "../components/Grid";
import { PageHero } from "../components/Hero";
import { Stack } from "../components/Stack";


const AboutPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutpage: mdx(
        frontmatter: {
          title: {
            eq: "About"
          }
        }
      ) {
        frontmatter {
          pageHeading
          pageDescription
          aboutImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }

      benefits: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "benefit"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              description
              icon
            }
          }
        }
      }

      careerBenefits: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "career-benefit"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
            }
          }
        }
      }

      serviceareas: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "service-area"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              areas
              flexDirection
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              imagePosition
              imageMaxW
              imageMaxH
              alt
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              source
              reviewer
              review
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title="About"
      />

      <SectionWrapper
        background="background.linen"
      >
        <SectionContainer>
          <PageHero
            hasBackground
            heading={ data.aboutpage.frontmatter.pageHeading }
            description={ data.aboutpage.frontmatter.pageDescription }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="141px 0 97px"
      >
        <SectionContainer>
          <AboutFlexbox
            direction="row-reverse"
            heading="About Vester Pest Control"
            description={[
              "Vester Pest Control has been servicing San Diego County for over 20 years. Our company is committed to the full protection of our clients, their health, and their property by promoting safety and environmental consciousness, by taking the time to screen and train our service technicians and by creating customized solutions to your pest control needs.",
              "Our job is to get your 100% service satisfaction. At Vester Pest Control, our work is also insured and warrantied."
            ]}
            image={ data.aboutpage.frontmatter.aboutImage.childImageSharp.gatsbyImageData }
            imageBackground="background.linkwater"
            imageMaxW={{
              base: '45%',
              sm: '320px',
              lg: '200px',
              xl: '350px',
              '2xl': '400px'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="0 0 80px"
      >
        <SectionContainer>
          <BenefitsCarousel
            nodes={ data.benefits.edges }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="76px 0 100px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            padding="0 1rem"
            heading="Masters of Our Craft"
            headingWidth={{
              base: '100%',
              lg: '585px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control was founded in 2000 by Dr. Armen Abramyan, who poured his extensive knowledge of biology, chemistry, and environmental safety into his vision of bringing pest management to the next level, with passion and care.",
              "We take pride in our work. We offer both conventional and eco-friendly solutions for your pest management needs. We show our commitment to defending your home and business by offering a guarantee on our services. Our team is available throughout San Diego County."
            ]}
            descriptionWidth={{
              base: '100%',
              md: '625px'
            }}
          />
        </SectionContainer>
        <Stack
          align="flex-start"
          spacing={{
            base: '50px',
            lg: '100px'
          }}
        >
          { data.serviceareas.edges.map(( area: any, _index: number ) => (
            <ServiceAreasFlexbox
              heading={ area.node.frontmatter.heading }
              areas={ area.node.frontmatter.areas }
              flexDirection={ area.node.frontmatter.flexDirection }
              image={ area.node.frontmatter.image.childImageSharp.gatsbyImageData }
              imagePosition={ area.node.frontmatter.imagePosition }
              imageMaxW={ area.node.frontmatter.imageMaxW }
              imageMaxH={ area.node.frontmatter.imageMaxH }
              alt={ area.node.frontmatter.alt }
            />
          ))}
        </Stack>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 40px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Benefits and Perks at Vester Pest Control"
            headingWidth={{
              base: '100%'
            }}
            headingMargin="0 auto"
            description={[
              <>Here, at Vester Pest Control, we're activement invested in the ongoing growth, success, and happiness of each and every one of our employees.</>,
              <>In addition to traditional benefits, such as <a href="https://www.anthem.com/ca" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "#F94E19" }}>health</a>, dental, and vision insurance, as well as in-house learning and development, we're proud to offer employees other exclusive perks and benefits, such as:</>
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
          <CareerBenefitsGrid>
            { data.careerBenefits.edges.map(( benefit: any, _index: number ) => (
              <CareerBenefitItem
                heading={ benefit.node.frontmatter.heading }
              />
            ))}
          </CareerBenefitsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ data.testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default AboutPage;
