import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  GridItem,
  Heading,
  Image,
  Text
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";


interface Props {
  heading: string
  description: string
  image: IGatsbyImageData
};


export const PestsGridItem: React.FC<Props> = ( props ) => {
  return (
    <GridItem>
      <Box
        position="relative"
        w="70px"
        h="70px"
        background="white"
        borderRadius="8px"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.05)"
      >
        <Image
          as={ GatsbyImage }
          image={ props.image }
          position="absolute"
          top="50%"
          left={{
            base: '80%',
            lg: '50%'
          }}
          w={{
            base: '120px',
            xl: '140px',
            '2xl': '160px'
          }}
          transform="translate(-50%, -50%)"
        />
      </Box>
      <Heading
        as="h3"
        margin={{
          base: '24px 0 16px',
          lg: '34px 0 24px'
        }}
        color="accent.900"
        fontSize="28px"
        fontWeight={ 400 }
        lineHeight="33px"
      >
        { props.heading }
      </Heading>
      <Text
        color="accent.900"
        fontSize="16px"
        lineHeight="21px"
      >
        { props.description }
      </Text>
    </GridItem>
  )
}
