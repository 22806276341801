import * as React from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";

import { IconFactory } from "../../../common/ScalableVectorGraphics";


interface Props {
  order: number
  icon: string
  heading: string
  list: string[]
};


export const ProcessItem: React.FC<Props> = ( props ) => {
  return (
    <Stack
      position="relative"
      direction={{
        base: 'column',
        lg: 'row'
      }}
      align={{
        base: 'flex-start',
        lg: 'center'
      }}
      spacing={ 8 }
      w={{
        base: '100%',
        lg: 'auto'
      }}
    >
      <Flex
        position={{
          base: 'absolute',
          lg: 'static'
        }}
        top="120px"
        left={ 0 }
        justifyContent="center"
        alignItems="center"
        w="32px"
        h="32px"
        background="primary.900"
        borderRadius="50%"
      >
        <Text
          color="white"
          fontSize="16px"
          fontWeight={ 700 }
          lineHeight="21px"
        >
          { props.order }
        </Text>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        w="120px"
        h="120px"
        background="background.linkwater"
        borderRadius="50%"
      >
        <IconFactory
          icon={ props.icon }
          color="primary.900"
          h="32px"
          w="auto"
        />
      </Flex>
      <Box
        w={{
          lg: '540px'
        }}
      >
        <Heading
          as="h2"
          color="accent.900"
          fontSize="28px"
          fontWeight={ 400 }
          lineHeight="33px"
        >
          { props.heading }
        </Heading>
        <UnorderedList
          stylePosition="inside"
        >
          { props.list.map(( item, _index ) => (
            <HStack
              alignItems="flex-start"
              spacing={ 1 }
            >
              <ListItem></ListItem>
              <Text
                color="accent.900"
                fontSize="16px"
                fontWeight={ 400 }
                lineHeight="21px"
              >
                { item }
              </Text>
            </HStack>
          ))}
        </UnorderedList>
      </Box>
    </Stack>
  )
}
