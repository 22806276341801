import * as React from "react";
import {
  Grid
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
}


export const CareerBenefitsGrid: React.FC<Props> = ( props ) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        sm: '1fr 1fr',
        md: '1fr 1fr 1fr'
      }}
      gap={{
        base: '16px 16px',
        lg: '60px'
      }}
      w="100%"
      margin={{
        base: '0 auto'
      }}
      padding="58px 0"
    >
      { props.children }
    </Grid>
  )
}
