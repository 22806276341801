import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Button,
  Flex,
  Grid
} from "@chakra-ui/react";

import { SectionContent } from "../../common/Section";


interface Props {
  heading?: string
  description?: string[]
  children: React.ReactNode
};


export const CommonPestsGrid: React.FC<Props> = ( props ) => {
  return (
    <>
      <SectionContent
        justifyContent="flex-start"
        alignItems="center"
        padding="0 1rem"
        textAlign="center"
        heading={ props.heading }
        headingWidth={{
          base: '100%',
          lg: '585px'
        }}
        headingMargin="0 auto"
        description={ props.description }
        descriptionWidth={{
          base: '100%',
          sm: '500px'
        }}
      />
      <Grid
        templateColumns={{
          base: '1fr 1fr',
          md: '1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr'
        }}
        gap={{
          base: '16px 16px',
          xl: '9.6px',
          '2xl': '60.8px'
        }}
        w={{
          base: '276px',
          sm: '416px',
          md: '632px',
          xl: '100%'
        }}
        margin={{
          base: '0 auto'
        }}
        padding="58px 0"
      >
        { props.children }
      </Grid>
      <Flex
        justifyContent="center"
      >
        <Button
          as={ GatsbyLink }
          to="/pest-library"
          variant="solid"
          size="lg"
          colorScheme="primary"
          border="1px"
          borderColor="primary.900"
          borderRadius="24px"
          _hover={{
            background: 'transparent',
            color: 'primary.900'
          }}
        >
          See Other Pests
        </Button>
      </Flex>
    </>
  )
}
